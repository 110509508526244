import React from "react"
import styled from "styled-components";
import { Text } from "../../core/commonExports";

import FireIcon from "../../../assets/images/fire_icon_sm.svg";
import BurglarytheftIcon from "../../../assets/images/burglary_theft_icon.svg";
import LightningIcon from "../../../assets/images/lightning_icon.svg";
import RiotsIcon from "../../../assets/images/riots_icon.svg";
import FloodIcon from "../../../assets/images/flood_icon.svg";
import MoreThreats from "../../../assets/images/more-threats-alt.svg";

const defaultPerils = [
  {
    icon: <FireIcon className="fire peril-icon" />,
    title: "Fire caused by short circuit, lightning etc",
  },
  {
    icon: <BurglarytheftIcon className="peril-icon" />,
    title: "Loss due to theft",
  },
  {
    icon: <FloodIcon className="peril-icon" />,
    title: "Damage due to flooding",
  },
  {
    icon: <RiotsIcon className="peril-icon" />,
    title: "Destruction by riots and strikes",
  },
  {
    icon: <LightningIcon className="peril-icon" />,
    title: "Damage due to storms",
  },
  {
    icon: <MoreThreats className="peril-icon no-fill" />,
    title: "and much more",
  }
]

const shopPerils = [
  {
    icon: <FireIcon className="fire peril-icon" />,
    title: "Fire caused by a short circuit, gas leak, etc.",
  },
  {
    icon: <BurglarytheftIcon className="peril-icon" />,
    title: "Loss of stock or cash due to theft",
  },
  {
    icon: <FloodIcon className="peril-icon" />,
    title: "Damage due to floods and storms",
  },
  {
    icon: <RiotsIcon className="peril-icon" />,
    title: "Destruction by third-parties",
  },
  {
    icon: <MoreThreats className="peril-icon no-fill" />,
    title: "and much more",
  }
]

const PerilsSection = ({shopInsurance = false}) => {
  let perils = defaultPerils
  if (shopInsurance) {
    perils = shopPerils
  }

  return (
    <PerilsContainer shopInsurance={shopInsurance}>
      {perils.map((peril, index) => (
        <div style={{display: 'flex', alignItems: 'center'}} key={`peril-${index}`}>
          {peril.icon}
          <Text fontSize="16px" mfontSize="12px" lineHeight="21px" mlineHeight="15px" color="#4C4C4C" fontWeight="500" style={{margin: "0", marginLeft: "8px", textAlign: "left"}}>{peril.title}</Text>
        </div>
      ))}
    </PerilsContainer>
  )
}

const PerilsContainer = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  max-width: 460px; 
  gap: ${p => p.shopInsurance ? "1.5rem 2.5rem" : "2rem"};
  margin-top: ${p => p.shopInsurance ? "1.5rem" : "3rem"};

  .peril-icon {
    width: 34px;
    height: 34px;
  }  
  .peril-icon > path {
    fill: #244BCE;
  }
  .fire {
    width: 60px;
    height: 60px;
  }

  .no-fill > path {
    fill: inherit;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 1rem;
    gap: ${p => p.shopInsurance ? "0.5rem 0rem" : "0.5rem"};
    .peril-icon {
      width: 24px;
      height: 24px;
    }
    .fire {
      width: 40px;
      /* height: 60px; */
    }
  }
`

export default PerilsSection